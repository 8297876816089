import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { parseSelectionMode } from './utils'
import { VehicleSelectionMode } from './types'

export const useSearchParamsSelectionMode = (): VehicleSelectionMode | undefined => {
  const [searchParams] = useSearchParams()

  return React.useMemo(() => parseSelectionMode(searchParams.get('selectionMode') ?? undefined), [searchParams])
}
