/// <reference types="vite-plugin-svgr/client" />
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { VehicleId } from '@api/endpoints'
import SvgIconComparison from '@assets/icons/compareIcon.svg?react'
import { useVehiclesIdCompareStore } from '@stores/useVehiclesIdCompareStore'
import { useLanguage } from '@hooks/useLanguage'
import { IconTextLink } from '@controls/buttons'
import styles from './ButtonAddToCompare.module.css'

type Props = Readonly<{
  vehicleId: VehicleId
  className?: string
}>

export const ButtonAddToCompare: React.FC<Props> = ({ vehicleId, className }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { routes } = useLanguage()

  const addVehicleToCompare = useVehiclesIdCompareStore((s) => s.addVehicle)

  const addToComparisonHandler = (vehicleId: VehicleId): void => {
    addVehicleToCompare({ id: vehicleId })
    navigate(routes.Comparison) //leave the navigation to the ComparisonPage?
    // TODO: do we need to make a notification that the car is added to the comparison list?
  }

  return (
    <IconTextLink
      className={clsx(styles.compareBtn, className)}
      onClick={() => addToComparisonHandler(vehicleId)}
      text={t('pages.vehicle.buttons.add_comparison')}
    >
      <SvgIconComparison className={clsx(styles.compareIcon, 'comparisonIcon')} />
    </IconTextLink>
  )
}
